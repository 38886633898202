<template>
  <header
    class="delay-navigation flex w-full items-center bg-white/95 transition duration-navigation"
    :class="{
      '!bg-transparent text-white': variant === 'transparent',
    }"
    data-cy-main-header
  >
    <app-page-wrapper class="flex items-center gap-6 @container">
      <nuxt-link class="hidden @[1050px]:block" to="/">
        <brand-navigation-logo :variant="variant" />
      </nuxt-link>

      <main-header-navigation-menu
        class="flex @[1050px]:hidden"
        :items="items"
        :variant="variant"
      />

      <div v-if="!noNavigation" class="flex-auto">
        <div class="flex items-center justify-end @[1050px]:justify-between">
          <div class="hidden @[1050px]:block">
            <main-header-navigation :items="items" :variant="variant" />
          </div>

          <main-header-user-menu
            v-if="isAuthenticated"
            data-cy-user-entry
            :variant="variant"
          />

          <ul v-else class="flex items-center gap-3">
            <li>
              <app-button
                block
                :color="variant === 'transparent' ? 'light' : 'dark'"
                data-cy-main-header-signin
                rel="nofollow"
                :to="{
                  name: 'auth-login',
                  hash: '#signin',
                  query: {
                    redirect: $route.fullPath,
                  },
                }"
                variant="tonal"
                >{{ $t("authentication.signin_navigation_label") }}</app-button
              >
            </li>

            <li class="hidden @[1050px]:block">
              <app-button
                block
                :color="variant === 'transparent' ? 'light' : 'dark'"
                :to="{ name: 'become-trainer' }"
                variant="outlined"
                >{{ $t("become_trainer.page.navigation_label") }}</app-button
              >
            </li>
          </ul>
        </div>
      </div>
    </app-page-wrapper>
  </header>
</template>

<script lang="ts" setup>
import BrandNavigationLogo from "~/business-areas/brand/components/brand-navigation-logo.vue";
import MainHeaderNavigation from "~/layouts/_includes/components/main-header-navigation.vue";
import MainHeaderNavigationMenu from "~/layouts/_includes/components/main-header-navigation-menu.vue";
import MainHeaderUserMenu from "~/layouts/_includes/components/main-header-user-menu.vue";
import type { MainHeaderVariant } from "~/layouts/_includes/main-header.model";

defineProps<{
  variant?: MainHeaderVariant;
  blur?: boolean;
  noNavigation?: boolean;
}>();

const { isAuthenticated } = useCurrentUser();
const { items } = useMainHeaderNavigation();
</script>
